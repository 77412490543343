.footer{
    background-color: #191818;
    height: 100%;
    position: relative;
}

.folder{
    position: absolute;
    display: flex;
    gap:0.5rem;
    align-items: center;
    margin-top: 1rem;
    margin-left: 2rem;
    
    color:whitesmoke;
}
.folder-div{
   grid-template-columns: 2/2;
    grid-template-rows: 1/2;
}
.footer-props{
    
    display: grid;
    grid-template-columns: repeat(5,20%);
    grid-template-rows: repeat(2,50%);
   
 }

 .playlist-logo{
    width: 60px;
    height: 60px;
    border-radius: 0.25rem;
    text-align: center;
    font-size: larger;
}

 .song{
    margin-left: 17rem;
    grid-template-columns: 1;
    width:500px;
    height:70px;
 }

 .details{
    display: flex;
    flex-direction: column;
    gap:0.25rem;
 }

 .currSong{
    font-size: medium;
    color: whitesmoke;
 }
 .artist{
    font-size: small;
    color: silver;
 }