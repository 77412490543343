.container{
    display: flex;
    flex-direction: column;
    gap:0.5rem; 
    width: 20vw;
    padding: 0.4rem 0.5rem;
}
.logo{
    width: 125px;
}
.ulist{
    color: rgba(255, 255, 255, 0.623);
    list-style: none;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    padding: 0.5rem 1rem;
    
}

.sidebar-links{
    /* background-color:  rgba(155, 120, 120, 0.254);
    border-radius: 1rem; */
    border-radius: 0.5rem;
    background-color: rgb(17, 17, 17);
}
li{
    display: flex;
    align-items: center;
    gap:1rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover{
        color: white;
    }
}

.li-playlist{
    color:whitesmoke;
    height: 3rem;
    padding: 0.4rem 1rem;
}
.li-playlist:hover{
    background-color:rgba(89, 88, 88, 0.393);
    border-radius: 0.5rem;
}
.playlists{
    display: flex;
    flex-direction: column;
    /* background-color: rgba(155, 120, 120, 0.254); */
    padding: 0.4rem 1rem;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0.5rem;
    background-color: rgb(17, 17, 17);
    
}
.library{
    display: flex;
    gap: 0.5rem;
    color: silver;
    align-items:center;
    cursor: pointer;
    margin-left: 1rem;
    margin-bottom: 1rem;
}
.library:hover{
    color: white;
}


.playlist-logo{
    width: 60px;
    height: 60px;
    border-radius: 0.25rem;
}

.details{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}

.playlist-artist{
    font-size: small;
    color: silver;
}

.playlist-name{
    font-size: medium;
}

.playlist-info{
    display: flex;
    flex-direction: column;
    gap:2px;
}

.add-icon{
    display: flex;
    align-items: flex-end;
    
}
.library-items{
    display: flex;
    align-items:center;
    justify-content: space-between;
    
}