.main{
display: grid;
grid-template-columns: repeat(4,20%); 
grid-template-rows: repeat(2,50%);
background-color: black;
box-sizing: border-box;
margin:0;
width: 100vw;
height:100vh;
row-gap: 5rem;
}
.box{
    grid-column: 4/5;
    grid-row: 2;
    margin-left: 100px;
    margin-top: 100px;
}
.login-main{
    /* display: flex;
    bottom: 0;
    flex-direction: column;
    height: 450px;
    width: 40rem;
    background-color: black; */
}

.grove-logo{
    width:300px;
    height:150px;

}

.login-btn{
    border-radius: 5rem;
    border: 0.2rem solid black;
    background-color: rgba(226, 53, 148, 0.772);
    width: 100px;
    height:40px;
    margin-left: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-weight: bolder;
    font-size: medium;
    cursor:pointer;
}

.lap-div{
  
    grid-column: 1/4;
    grid-row: 1/3;
}
.laptop{
    width:100%;
    height: 100%;
}
