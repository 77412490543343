body{
    background-color: black !important;
    padding: 0.5rem 0.1rem;
}
.container{
    width: 100vw;
    height: 100vh;
    display: grid;
    overflow: hidden;
    grid-template-rows: 90vh 10vh;
 }
 
 .grovify_body{
     display: grid;
     grid-template-columns: 20vw 80vw;
     height: 100%;
     width: 100%;
     gap:1rem;
     
 }
 .body{
     height: 90vh;
     width: 79vw;
     background: linear-gradient(transparent,rgb(0, 0, 1));
     background-color: rgb(69, 23, 74);
     border-radius: 1rem !important;
    
 }

 .body_contents{
    padding: 0.4rem 1rem;
    

 }
