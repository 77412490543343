.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 3rem;
    height: 10vh;
    position: sticky;
    transition: 0.3s ease-in-out;

}
.search{
    background-color:  rgba(30, 28, 29, 0.587);
    width:30rem;
    padding: 0.4rem 1rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    gap:0.5rem;
}
input{
    border:none;
    height: 2rem;
    width: 100%;

}
input:focus{
    outline:none;
}
.avatar{
    background-color:  rgba(30, 28, 29, 0.587);
    padding:0.3rem 0.4rem;
    padding-right: 1rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;

}
a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:0.5rem;
    text-decoration: none;
    color: white;
    font-weight: bold;
}

.search-ip{
    background-color:rgba(30, 28, 29, 0.148);
    color: white;
}