html{
    --fallback-fonts: Helvetica Neue,helvetica,arial,Hiragino Kaku Gothic ProN,Meiryo,MS Gothic;
}
body{
    font-family: var(--font-family,CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva,var(--fallback-fonts,sans-serif));
}
.selected-playlist{
    width:100%;
   
}
.playlist-img{
    width: 200px;
    height: 200px;
    border-radius: 0.5rem;
}

.playlist-details{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: flex-end;
    background-color:  rgba(143, 15, 34, 0.144);
    
    /* background: linear-gradient( rgba(155, 47, 92, 0.479) ,rgba(155, 47, 92, 0.479),rgb(51, 71, 73)); */
    padding: 1rem 1rem;
    border-radius: 0.5rem;
}
.playlist-highlights{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.playlistName{
    font-size: 50px;
    color: white;
}
.playlistArtist{
    font-size: 15px;
    color: white;
}
.noOfsongs{
    font-size: 15x;
    color: white;
}
.extra{
    display: flex;
    align-items: center;
    gap:0.5rem;
}

.items{
    display: flex;
    
}
.index{
color: white;
margin-right: 1rem;
}
.li-items{
    color: whitesmoke;
    display: flex;
    align-items: flex-start;
    padding: 8px;
    cursor: pointer;
    width: 100%;
}
.li-items:hover{
    background: linear-gradient(transparent,rgba(0, 0, 1, 0.533));
}
.ol-items{
    display: flex;
    list-style-type: decimal;
    flex-direction: column;
    gap:0.1rem;
    color: whitesmoke;
    align-items: flex-start;
    width: 100%;
}

.songName{
    font-size: medium;
}
.artist{
    font-size: small;
    color:silver;
}
.items{
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height:40vh;
}

.list{
 background: linear-gradient( rgba(160, 82, 117, 0.236),rgba(47, 151, 155, 0.142) );
 /* height: 50vh; */
 padding: 0.4rem 1rem;
 border-radius: 0.5rem;
 
}
.table{
   
}
td{
    color:silver;
    padding: 8px;
}

.mainlist{
    /* background: linear-gradient( rgb(122, 38, 59) 220px ,rgb(0, 0, 1)90%);
    padding: 0.4rem 1rem; */
    /* background-color: rgb(122, 38, 59); */
    
}

.options{
    display: flex;
    gap:1rem;
    margin-bottom: 1rem;
    align-items: center;
}

.table{
    width: 100%;

}

.tbody{
    width: 100%;
}