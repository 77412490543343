.div-bg{
    width:100vw;
    height:100px;
    display: flex;
    gap:10rem;
    padding: 1rem 1rem;
}
.audio-div{
    width:500px;
    height:70px;
    grid-template-columns: repeat(2,50%);
    grid-template-rows: repeat(2,50%);
    
  
}

.box-div{
    width:500px;
    height:70px;
    display: flex;
    flex-direction: column;
    grid-column: 2/3;
    grid-row: 1;

}

.stack-div{
    display:flex;
    justify-content: flex-start;
    flex-direction: row;
   
    width: 10rem;
    align-items: center;
    gap: 10px;
}
.stack2-div{
    display:flex;
    width: 40%;
    align-items: center;
    gap: 10px;
    grid-row: 1;
    grid-column: 3/5;
    justify-content: flex-end;
    align-self: center;
}
.stack3-div{
    display: flex;
    align-items: center;
    direction: row;
    gap: 0.5rem;
    grid-row: 2;
    grid-column: 1/4;
    width: 100%;
    align-self: start;
}
.stack4-div{
    display:flex;
    width: 40%;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
}

.options{
    display: flex;
    align-items: center;
    justify-content: center;
    align-self:first baseline;
}